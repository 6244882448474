import React from "react"
import {graphql, Link} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import HeaderSection from "../components/headerSection"
import Menu from "../components/services/menuServices"
import FirstSectionYoga from "../components/yoga/firstSection"
import SecondSectionConsulting from "../components/consulting/secondSection"

export const query = graphql`
query {
  sanityConsulting {
    subtitleEs
    phraseEs
    textEs
    text2Es
    image {
      asset {
        fluid(maxWidth: 2240) {
          ...GatsbySanityImageFluid
        }
      }
    }
    secondImage {
      asset {
        fluid(maxWidth: 1080) {
          ...GatsbySanityImageFluid_noBase64
        }
      }
    }
  }
}
`

const Consultoría = ({data}) => {
  const {subtitleEs, phraseEs, textEs, text2Es, image, secondImage} = data.sanityConsulting

  return(
    <Layout>
      <SEO title="Consultoría"/>
      <div className="language"><Link className="clickable" to="/consulting">ENG</Link> / <Link className="clickable" to="/consultoria">ESP</Link></div>

      <HeaderSection title="Consultoría" description={subtitleEs} maxWidth="604px"/>

      <FirstSectionYoga phrase={phraseEs} image={image} classes="consulting"/>

      <SecondSectionConsulting text={textEs} text2={text2Es} secondImage={secondImage} es={true} />

      <Menu/>

    </Layout>
  )
}

export default Consultoría
